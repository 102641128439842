import chatState from "./Util/ChatState";
import visitorToken from "./Util/VisitorToken";
import axios from "axios";
if (typeof Promise === "undefined") {
  require("promise/lib/rejection-tracking").enable();
  window.Promise = require("promise/lib/es6-extensions.js");
}

axios
  .get(process.env.API_URL + "/widget", {
    params: {
      vt: visitorToken.getVisitorToken(),
      path: window.location.pathname,
      state: chatState.getIsChatting() ? "chat" : "",
      trengoKey: window.Trengo.key,
    },
  })
  .then((res) => {
    window.Trengo.widgetLoaded = new Date().getTime();

    if (res.data.error) {
      console.error("Trengo widget error");
      return;
    }

    let frame = createIframe();
    frame.contentWindow.Trengo = window.Trengo;
    frame.contentWindow.TrengoShimData = res.data;

    // Temp fix for Firefox..
    if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
      createScript(document, Math.floor(new Date().getTime() / 60000));
      window.TrengoShimData = res.data;
    } else {
      createScript(frame.contentWindow.document, res.data.version);
    }
  });

/**
 * @returns {HTMLIFrameElement}
 */
function createIframe() {
  const frame = document.createElement("iframe");
  frame.id = "trengo-widget";
  frame.style.display = "none";
  document.body.appendChild(frame);

  const scriptEl = document.querySelector("#trengo-widget-script");
  if (scriptEl && scriptEl.getAttribute("nonce")) {
    frame.setAttribute("nonce", scriptEl.getAttribute("nonce"));
  }
  return frame;
}

/**
 * @param frame
 * @param version
 */
function createScript(doc, version) {
  let script = document.createElement("script");

  let scriptEl = document.querySelector("#trengo-widget-script");
  if (scriptEl && scriptEl.getAttribute("nonce")) {
    script.setAttribute("nonce", scriptEl.getAttribute("nonce"));
  }

  script.type = "text/javascript";
  script.async = true;
  script.src = process.env.CDN_URL + "widget.js?id=" + version;
  doc.head.appendChild(script);
}
