export default
{
    key: 'TrengoWidget_is_chatting',

    getIsChatting()
    {
        return window.localStorage.getItem(this.key) == 'true';
    },

    setIsChatting()
    {
        if (this.getIsChatting()) {
            return;
        }

        window.localStorage.setItem(this.key, true);
    },

    setIsNotChatting()
    {
        window.localStorage.removeItem(this.key);
    },
    
    getVisitorData(greeting = null) {
        return JSON.stringify({
            b: window.parent.innerWidth,
            c: window.parent.innerHeight,
            //d: greeting,
        })
    }
}