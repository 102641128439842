import idGenerator from './IdGenerator';

export default
{
    getVisitorToken()
    {
        var key = 'Trengo_VisitorToken';
        if (!window.localStorage.getItem(key)) {
            var id = idGenerator.generateUuid();
            window.localStorage.setItem(key, id);
        }
        else {
            id = window.localStorage.getItem(key);
        }

        if (window.Trengo.http) {
            window.Trengo.http.defaults.params.vt = id;
        }
        
        return id;
    }
}